



























































import { FeedbackSortCol, SortDir } from '@/shared';
import sink from '@/sink';
import { defineComponent, onMounted } from '@vue/composition-api';
import {
  FeedbackPageUpdated,
  FeedbackTableMounted,
  FeedbackTableSorted,
} from './+state';

export default defineComponent({
  setup() {
    onMounted(sink.lazyBroadcast(FeedbackTableMounted()));

    const sortCol = sink.select('feedback.sort-col');
    const sortDir = sink.select('feedback.sort-dir');

    const updateSort = (sortInfo: { col: FeedbackSortCol; dir: SortDir }) =>
      sink.broadcast(FeedbackTableSorted(sortInfo));

    const onChangeSortColumn = (col: FeedbackSortCol) => {
      updateSort({ col, dir: sortDir.value });
    };

    const onChangeSortDirection = (isDesc: boolean) => {
      updateSort({ col: sortCol.value, dir: isDesc ? 'desc' : 'asc' });
    };

    return {
      updateSort,
      onChangeSortColumn,
      onChangeSortDirection,
      updatePage: page => {
        sink.broadcast(FeedbackPageUpdated({ page }));
      },
      feedback: sink.select('feedback.table'),
      sortCol,
      sortDir,
      count: sink.select('feedback.count'),
      pageNumber: sink.select('feedback.page-number'),
      headers: [
        { value: 'feedbackDate', text: 'Feedback Date', sortable: true },
        {
          value: 'patientName',
          text: 'Patient',
          sortable: true,
        },
        {
          value: 'feedbackProvider',
          text: 'Provider',
          sortable: true,
        },
        { value: 'feedback', text: 'Feedback', sortable: false },
        { value: 'feedbackId', text: 'Feedback ID', sortable: false },
      ],
    };
  },
});
