








import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { FeedbackExportClicked } from './+state';

export default defineComponent({
  setup() {
    return {
      exportCsv: sink.lazyBroadcast(FeedbackExportClicked()),
    };
  },
});
