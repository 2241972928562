







import { ListPageLayout } from '@/shared';
import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import Actions from './Actions.vue';
import Table from './Table.vue';

export default defineComponent({
  components: {
    ListPageLayout,
    Table,
    Actions,
  },
  setup() {
    return {
      featureEnabled: sink.select('capabilities.features.feedback'),
    };
  },
  watch: {
    featureEnabled(isEnabled, wasEnabled) {
      if (!isEnabled && wasEnabled) {
        console.warn(
          `feedback enabled? ${wasEnabled} => ${isEnabled}, returning user to landing`,
        );
        this.$router.push('/');
      }
    },
  },
  mounted() {
    if (!this.featureEnabled) {
      console.warn(
        'feedback not enabled when component mounted, returning user to landing',
      );
      this.$router.push('/');
    }
  },
});
